class Clevertap {
    constructor(rgpd_accepted) {
        this.rgpd_accepted = rgpd_accepted;
        this.initialize();
    }

    initialize() {
        if (!window.clevertap && this.rgpd_accepted === "true") {
            window.clevertap = {event: [], profile: [], account: [], onUserLogin: [], notifications: [], privacy: []};
            window.clevertap.account.push({'id': (window.landingEnv).TOPUP_CLEVERTAP_ID});
            (function () {
                var wzrk = document.createElement('script');
                wzrk.type = 'text/javascript';
                wzrk.async = true;
                wzrk.src = ('https:' == document.location.protocol ? 'https://d2r1yp2w7bby2u.cloudfront.net' : 'http://static.clevertap.com') + '/js/a.js';
                var s = document.getElementsByTagName('script')[0];
                s.parentNode.insertBefore(wzrk, s);
            })();
            this.visitedPage();
        }
    }

    visitedPage() {
        this.sendEvent("topup-landingpage", {});
    }

    callToActionClicked() {
        this.sendEvent("topup-landingpage-sent", {});
    }

    sendEvent(eventName, eventProperties) {
        window.clevertap.event.push(eventName, eventProperties);
    }
}

export default Clevertap;
