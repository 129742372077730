  
import stringsDe from '../locale/strings.de';
import stringsEn from '../locale/strings.en';
import stringsEs from '../locale/strings.es';
import stringsFr from '../locale/strings.fr';
import stringsIt from '../locale/strings.it';
import stringsNl from '../locale/strings.nl';
import stringsPt from '../locale/strings.pt';

let phrases = {
	de: stringsDe,
	en: stringsEn,
	es: stringsEs,
	fr: stringsFr,
	it: stringsIt,
	nl: stringsNl,
	pt: stringsPt
};

function getLanguageFromLocale() {
	const locale = window.navigator.language;
	return locale.split('-')[0];
}

/* If we have a GET parameter lang then we use it */
function getUrlParameter(sParam) {
	const sPageURL = decodeURIComponent(window.location.search.substring(1));
	const sURLVariables = sPageURL.split('&');
	let sParameterName;

	for (let i = 0; i < sURLVariables.length; i++) {
		sParameterName = sURLVariables[i].split('=');

		if (sParameterName[0] === sParam) {
			return !sParameterName[1] ? undefined : sParameterName[1];
		}
	}
}

function getLanguage() {
	let language = getLanguageFromLocale();

	const language_url = getUrlParameter('lang');
	if (typeof language_url !== 'undefined') {
		language = language_url
	}

	return language;
}

function getKeys(obj) {
	const keys = [];
	let key;
	for (key in obj) {
		keys.push(key);
	}
	return keys;
}

function getString() {
	return phrases[getKeys(phrases).some(function (elt) {
		return elt === getLanguage();
	}) ? getLanguage() : "en"];
}

export function translate() {
	let localizedElements = document.querySelectorAll('[data-loc-id]');
	const translations = getString();
	localizedElements.forEach(element => {
		const key = element.getAttribute('data-loc-id');
		let translation = translations[key];
		element.innerHTML = translation ? translation : '[' + key + ']';
	});
}

export function getTranslation(key) {
	return getString()[key];
}
