// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("intl-tel-input/build/img/flags.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("intl-tel-input/build/img/flags@2x.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".iti__flag {background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");}\n.iti {\n    display: block;\n}\n@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {\n    .iti__flag {background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");}\n    .iti {\n        display: block;\n    }\n}\n", ""]);
// Exports
module.exports = exports;
