import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils';
import '../img/like-1.png';
import '../img/locked-4.png';
import '../img/stopwatch-4.png';
import '../img/logo-libon.png';
import '../css/jumbotron.css';
import 'intl-tel-input/build/css/intlTelInput.min.css'
import '../css/tel-input.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import bsn from 'bootstrap.native';
import Clevertap from './clevertap';
import {getTranslation, translate} from './language';

translate();

const urlParams = new URLSearchParams(window.location.search);

if(urlParams.has("promo") && urlParams.get("promo") === "true") {
// Promotion Text See More/Less Logic
    const seeMore = document.querySelector("#see-more");
    const dots = document.querySelector("#dots");
    const moreText = document.querySelector("#more");
    const seeMoreTranslated = getTranslation("PROMOTION_SEE_MORE");
    const seeLessTranslated = getTranslation("PROMOTION_SEE_LESS");
    seeMore.addEventListener("click", function () {
        if (dots.style.display === "none") {
            dots.style.display = "inline";
            seeMore.innerHTML = seeMoreTranslated;
            moreText.style.display = "none";
        } else {
            dots.style.display = "none";
            seeMore.innerHTML = seeLessTranslated;
            moreText.style.display = "inline";
        }
    });
} else {
    const promotionContainer = document.getElementById("promoContainer");
    promotionContainer.style.display = "none";
}



let clevertapService;
const rgpdFooter = document.querySelector("#footer-rgpd");
window.onload = rgpdCheck;
function rgpdCheck() {
    let rgpdValue = localStorage.getItem('libon_rgpd_accepted');
    if (rgpdValue === null) {
        document.querySelector("#decline-rgpd-btn").addEventListener("click", () => setUpRGPD("false"));
        document.querySelector("#accept-rgpd-btn").addEventListener("click", () => setUpRGPD("true"));
        rgpdFooter.hidden = false;
    } else {
        clevertapService = new Clevertap(rgpdValue);
    }
}

function setUpRGPD(value) {
    localStorage.setItem('libon_rgpd_accepted', value);
    clevertapService = new Clevertap(value);
    rgpdFooter.hidden = true;
}

const input = document.querySelector('#msisdn');
const submit = document.querySelector('#submit');
window['intlTelInput'] = intlTelInput;
const iti = window.intlTelInput(input, {
    separateDialCode: false,
    preferredCountries: ['ml', 'ci', 'sn', 'ph', 'ao', 'ng', 'cd']
});

input.addEventListener("countrychange", function () {
    input.setAttribute("placeholder", "+" + iti.getSelectedCountryData().dialCode + "XXX");
    checkValidity();
});

input.addEventListener("input", function () {
    if (input.value.length === 2 && input.value === "00") {
        input.value = "+";
    }
    checkValidity();
});

function checkValidity() {
    const isNumberValid = iti.isValidNumber();
    submit.disabled = !isNumberValid;
    input.setCustomValidity(isNumberValid ? "" : "Numéro invalide");
}

const warnlist = ['fr', 'us', 'sa', 'kw', 'es', 'ca', 'it', 'gb', 'be'];
function isWarnListed(element) {
    return warnlist.includes(element);
}

var ready = (callback) => {
    if (document.readyState !== "loading") callback();
    else document.addEventListener("DOMContentLoaded", callback);
};

ready(() => {
    new bsn.Modal(document.querySelector('#form-valid-modal'), {}).show();
    document.querySelector('#warning-modal').hidden = true;
    document.querySelector('#default-modal').hidden = false;
    document.querySelector("#redirection-button").addEventListener("click", event => {
        window.location = 'https://libon.app/naxz'
    });
});
